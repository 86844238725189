import PropTypes from "prop-types";
import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const UserLayout = () => {
  // Scroll Top to 0
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div id="layout-wrapper">
          <Header />
          <Sidebar />
          <div className="main-content">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};
export default UserLayout;
