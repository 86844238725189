import React, { useState, useEffect } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { Link } from "react-router-dom"

// users
import user4 from "../assets/images/users/avatar-4.jpg"
import useAuth from "../hooks/useAuth"

const ProfileMenu = props => {
  const {userData} = useAuth();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 username">{userData.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/account/profile">
            <i className="bx bx-user font-size-16 align-middle me-1"></i>
            View Profile
          </DropdownItem>
          <DropdownItem tag="a" href="/account/change-password">
            <i className="bx bx-check-shield font-size-16 align-middle me-1"></i>
            Change Password
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}


export default ProfileMenu;