import React, { useEffect, useRef , useCallback} from "react"
//Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { Link , useLocation } from "react-router-dom"

const SidebarContent = props => {
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);
  
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  const location = useLocation();
  useEffect(() => {
    const pathName = location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove("active");
        items[i].parentElement.classList.remove("mm-active");
      }
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
        
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, []);
  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu </li>
            <li>
              <Link to="/home" className=" waves-effect">
                <i className="mdi mdi-home"></i>
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/shop/email-pass" className=" waves-effect">
                <i className="mdi mdi-email-search"></i>
                <span>Shop Email Pass</span>
              </Link>
            </li>
            <li>
              <Link to="/orders" className=" waves-effect">
                <i className="mdi mdi-database"></i>
                <span>My Orders</span>
              </Link>
            </li>
            <li>
              <Link to="/invoices" className=" waves-effect">
                <i className="mdi mdi-dresser"></i>
                <span>Invoices</span>
              </Link>
            </li>
            <li className="menu-title">Manage Account</li>
            <li>
              <Link to="/account/profile" className=" waves-effect">
                <i className="mdi mdi-account-box-multiple"></i>
                <span>Manage Profile</span>
              </Link>
            </li>
            <li>
              <Link to="/account/change-password" className=" waves-effect">
                <i className="mdi mdi-shield-account"></i>
                <span>Change Password</span>
              </Link>
            </li>
            <li className="menu-title">Others</li>
            <li>
              <Link to="/support" className=" waves-effect">
                <i className="mdi mdi-heart-pulse"></i>
                <span>Support</span>
              </Link>
            </li>
            <li>
              <Link to="/logout" className=" waves-effect">
                <i className="mdi mdi-logout"></i>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent;