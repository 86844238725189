import React, { useState } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import { getCartCount } from "../helpers/EmailPassExtra";

// Import menuDropdown
import ProfileMenu from "./ProfileMenu";
import logo from "../assets/images/Logo_White.png";

const Header = () => {
  return (
      <header id="page-topbar">
        <div className="navbar-header">
          <Container fluid>
            <div className="float-end">
            <Link to="/cart" className="btn header-item noti-icon right-bar-toggle waves-effect mt-4">
              <i className="mdi mdi-cart"></i>
              <span className="badge rounded-pill bg-danger" id="cartItemsCount">{getCartCount()}</span>
            </Link>
              <ProfileMenu />
            </div>
            <div>
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-light pt-2">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="20" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="19" />
                  </span>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </header>
  );
};

export default Header;
