import {React, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "./routes/RequireAuth";
import AuthCheck from "./routes/AuthCheck";
import useAuth from "./hooks/useAuth";

// Import Layout
import UserLayout from "./layouts/UserLayout";
// Import scss
import "./assets/scss/theme.scss";

const App = (props) => {

  // Auth
  const { setAuth } = useAuth();
  const Login = lazy(() => import("./views/auth/Login"));
  const Register = lazy(() => import("./views/auth/Register"));

  // User
  const Home = lazy(() => import("./views/Home"));
  const Shop_Email_Pass = lazy(() => import("./views/shop/EmailPass.js"));
  const Orders = lazy(() => import("./views/Orders.js"));
  const Invoices = lazy(() => import("./views/Invoices.js"));
  const InvoiceDetails = lazy(() => import("./views/InvoiceDetails.js"));
  const Account_Profile = lazy(() => import("./views/account/Profile.js"));
  const Account_Change_Password = lazy(() => import("./views/account/ChangePassword.js"));
  const Support = lazy(() => import("./views/Support.js"));
  const Cart = lazy(() => import("./views/Cart"));
  const Plan = lazy(() => import("./views/Plan"));

  //error pages
  const Page404 = lazy(() => import("./views/errors/Page404"));
  const Logout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
      window.location = "/login";
  }

  return (
    <Routes>
      <Route path="/">
        <Route path="/" element={<Navigate to="/home" />}/>
        
        {/* public routes */}
        <Route element={<AuthCheck />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>

        {/* we want to protect these routes */}
        <Route element={<RequireAuth />}>
          <Route element={<UserLayout />}>
            <Route path="/logout" element={<Logout />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/plans" element={<Plan />} />
            <Route path="/home" element={<Home />} />
            <Route path="/shop/email-pass" element={<Shop_Email_Pass />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/invoices/:invoice_no" element={<InvoiceDetails />} />
            <Route path="/account/profile" element={<Account_Profile />} />
            <Route path="/account/change-password" element={<Account_Change_Password />} />
            <Route path="/support" element={<Support />} />
            <Route path="/cart" element={<Cart />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
};

export default App;
