import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Row } from "reactstrap";

import HttpApi from "../api/http";
import SidebarContent from "./SidebarContent";
import { ToastError } from "../components/Toast";
import {getUserData} from "../helpers/UserHelper";
import avatar2 from "../assets/images/users/avatar-2.jpg";

const Sidebar = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [activePaymentGateways, setActivePaymentGateways] = useState([]);

  let navigate = useNavigate();
  let userData = getUserData();

  const createInvoice = (e) => {
    e.preventDefault();
    let formData = {};
    const form = new FormData(e.currentTarget);
    for (let [key, value] of form.entries()) {
      formData[key] = value;
    }

    if (formData.amount < 10) {
      ToastError("Minimum deposit is $10");
      return;
    }

    if (!("paymentMethod" in formData)) {
      ToastError("Please select Payment Method !");
      return;
    }

    HttpApi.createInvoice(formData)
    .then((response) => {
      navigate('/invoices/' + response.data?.invoice_no);
      setmodal_center(!modal_center);
    })
    .catch((e) => {
      ToastError(e.response.data.message);
    });

  };

  const addFund = () => {
    if(userData.plan_id == null){
      navigate('/plans');
    }else{
      HttpApi.addFund()
        .then((response) => {
          setActivePaymentGateways(response.data);
          setmodal_center(!modal_center);
        })
        .catch((e) => {
          ToastError(e.response.data.message);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            <div className="user-img">
              <img
                src={avatar2}
                alt=""
                className="avatar-md mx-auto rounded-circle"
              />
            </div>

            <div className="mt-1">
              <Link to="#" className="text-white fw-medium font-size-16 username">
                {userData?.name}
              </Link>
              <div className="mt-1">
                <p>Available Balance</p>
                <h6>$ {userData?.balance}</h6>
                <Button color="primary" className="btn-sm" onClick={addFund}>
                  Add Funds
                </Button>
              </div>
            </div>
          </div>
          <div data-simplebar className="h-100">
            <SidebarContent />
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          setmodal_center(!modal_center);
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Fund</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={createInvoice}>
            <Row className="mb-3">
              <label className="col-md-4 col-form-label">Amount</label>
              <div className="col-md-8">
                <input
                  className="form-control"
                  type="number"
                  name="amount"
                  defaultValue={10}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <label className="col-md-4 col-form-label">Payment Method</label>
              <div className="col-md-8">
                {activePaymentGateways.map((p) => (
                  <div className="form-check form-check-inline font-size-16">
                    <input
                      id={p.name}
                      name="paymentMethod"
                      type="radio"
                      className="form-check-input form-check-input"
                      value={p.name}
                    />
                    <label
                      htmlFor={p.name}
                      className="form-check-label font-size-13 form-label"
                    >
                      {p.name == "btc" ? (
                        <i className="fab fa-bitcoin me-1 font-size-20 align-top"></i>
                      ) : null }
                      {p.name == "perfect_money" ? (
                        <i className="fab fa-product-hunt me-1 font-size-20 align-top"></i>
                      ) : null}
                      {p.title}
                    </label>
                  </div>
                ))}
              </div>
            </Row>
            <Button color="primary" className="btn-sm" type="submit">Add Fund</Button>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Sidebar;
